import React, { Fragment, useState } from "react";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { DashboardModal, useUppy } from "@uppy/react";
import API from '../api-routes'
import UseToken from "../api-authorization/UseToken";
import { randomTextGenerator } from "./randomStringGenerator";

// type propType ={
//   display:boolean,
//   setDisplay:(display:boolean)=>void;
//   fileSession?:string, 
//   setFileId:(id:number) => void,
//   token:string,
//   storageType:number;
// }



const ButtonFileUploader = (props) => {
//   const {
//     label,
//     input: { value, onChange },
//     imgPath,
//     changeTitle,
//     deleteTitle,
//   } = props;

  const [uploadedFile, setUploadedFile] = useState(null);
  const [open, setOpen] = useState(false)
  const token = UseToken()

  const onDelete = () => {
    setOpen(false)
    // onChange(null);
    setUploadedFile(null);
  };

  const uppy = new Uppy({
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: ['.xlsx' , 'image/*','.sav' , '.txt'],
        maxNumberOfFiles: 1
      },
      meta:{
        storageType:props.storageType,
        session:randomTextGenerator(10),
        organizationId:1
      }
    })
      .use(Tus, {
        endpoint: API.file,
        overridePatchMethod: true,
        chunkSize: 5000000,
        // withCredentials: true,
        headers: {
          Authorization:`Bearer ${token}`,
        },
      })

      // .use(Url, {
      //   // target: Dashboard,
      //   companionUrl: "https://uppy.mineomix.com",
      // })
      .on("complete", (file) => {
        
        if(file.successful && file.successful.length > 0){
          const url = file.successful[0].uploadURL;
          const idStr = url.slice(url.lastIndexOf('/')+1);
          props.setFileId(+idStr)
        //   onChange(Number(idStr))
          setUploadedFile(file.successful? file.successful[0].preview:null)
          console.log("file" , file.successful)
        }
      });


  console.log("uploadedFile",uploadedFile)
  return (
    token?
     props.display?
      <DashboardModal
        uppy={uppy}
        open={props.display}
        closeAfterFinish={true}
        closeModalOnClickOutside={true}
        plugins={["ImageEditor"]}
        proudlyDisplayPoweredByUppy={false}
        onRequestClose={() => props.setDisplay(false)}
        metaFields={[
          { id: 'name', name: 'Name', placeholder: 'File name' },
      ]}
      />
      :null
      :null
  );
};

export default ButtonFileUploader;
