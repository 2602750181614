import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import TableComponent from '../components/TableComponent';
import {getTermsAndConditionApprovalList,getTermsAndConditionFile,updateTermsAndConditions, AddTermsAndConditions} from './actions';
import NewTermsAndConditionForm from "./NewTermsAndConditionForm";
import ActionMenu from "./ActionMenu";
import EditTermsAndConditionsModal from "./EditTermsAndConditionsModal";
import SimpleLoading from "../components/simpleLoading";
import apiRoutes from "../api-routes";
import axios from "axios";
import authService from "../api-authorization/AuthorizeService";
import UseToken from "../api-authorization/UseToken";

const TermsAndConditionsList = (props) => {
    const {t  , getAllTermsAndConditions , addNewTermsResult , getResults} = props;
    const [loading ,  setLoading] = useState(false)
    const [dtParameter , setDtParameter] = useState({
        Current:1,
        pageSize:10
    })
    const [rows , setRows] = useState([])
    const [columns  , setColumns] = useState(null)
    const [showTermsAndConditionsForm,setShowTermsAndConditionsForm] = useState(false)
    const [showEditTermsAndConditionsFrom , setShowEditTermsAndConditionsFrom] = useState(false)
    const [selectedField , setSelectedField] = useState(null)
    const [TermsAndConditionsEditId  , setTermsAndConditionsEditId] = useState(0)
    const [openMenu , setOpenMenu] = useState(false)
    const [termsAndConditionsFileId, setTermsAndConditionsFileId] = useState(0)
    const [errorMessage , setErrorMessage] = useState(null)
    const [termsAndConditionsText,setTermsAndConditionsText] = useState("")
    const token = UseToken();

    useEffect(()=>{
        if(!showEditTermsAndConditionsFrom){
            setTermsAndConditionsEditId(null)
        }
    },[showEditTermsAndConditionsFrom])

    useEffect(()=>{
        props.getTermsAndConditionApprovalList({
            dtParameter:dtParameter,
            isTemplate:true
        })
    },[])

    useEffect(()=>{
        if(!TermsAndConditionsEditId)
            return
        setShowEditTermsAndConditionsFrom(true)
    },[TermsAndConditionsEditId])

    
    useEffect(()=>{
        if(!dtParameter)
            return
        props.getTermsAndConditionApprovalList({
            dtParameter:dtParameter,
            isTemplate:true
        })
    },[dtParameter])

    useEffect(()=>{
        if(!getAllTermsAndConditions||_.isEmpty(getAllTermsAndConditions))
            return
            setRows(
                getAllTermsAndConditions.data
                )
        if(!columns){
            setColumns([...getAllTermsAndConditions.columns,
                {field:'Id', headerName:'Action' , flex:1, renderCell: (params) =>(
                    <ActionMenu 
                        id={params.row} 
                        handleDownload={() =>downloadFileInBrowser(params.row.activeVersionId)} 
                        handleEdit = {()=>setTermsAndConditionsEditId(params.row.id)} 
                        // handleViewHistory={()=>props.history.push("/termsAndConditionsVersionsHistory/"+params.row.id)} 
                        // handleViewAccepted={()=>props.history.push('/termsAndConditionsAcceptedUsers/'+params.row.id)}
                    />
                )}
            ])
        }
    },[getAllTermsAndConditions])

    useEffect(()=>{
        if(!addNewTermsResult)
            return 
        setLoading(false)
        if(addNewTermsResult.succeeded){
            setShowTermsAndConditionsForm(false)
            props.getTermsAndConditionApprovalList({
                dtParameter:dtParameter,
                isTemplate:true
            })
        }else if(addNewTermsResult.result){
            setErrorMessage(addNewTermsResult.result.error)
        }
    },[addNewTermsResult])



    useEffect(()=>{
        if(!getResults)
            return 
        if(getResults.type =='UPDATE_TERMS'){
            setLoading(false)
            if(getResults.succeeded){
                setShowEditTermsAndConditionsFrom(false)
                props.getTermsAndConditionApprovalList({
                    dtParameter:dtParameter,
                    isTemplate:true
                })
            }
        }
    },[getResults])

    const submitTermsAndConditions = (values) => {
        setLoading(true)
        if(values.type == 'File' && termsAndConditionsFileId){
            const data = { 
                TermsFileId : termsAndConditionsFileId,
                title : values.title,
                termsAndConditionStatus:1,
                versionName : values.versionName,
                isTemplate:true
            }
            props.AddTermsAndConditions(data)
        }else if(values.type == 'Text'){
            const data ={ 
                title : values.title,
                text : termsAndConditionsText,
                termsAndConditionStatus:0,
                versionName:values.versionName,
                isTemplate:true
            }
            props.AddTermsAndConditions(data)
        }
    }

    const downloadFileInBrowser = async (id) => {
        const config = {
            responseType: 'blob',
            headers:{
                'Authorization' : `Bearer ${token}`,
                'Access-Control-Expose-Headers':'Content-Disposition'
            }
          };
        return await axios
          .get(`${apiRoutes.api}api/file/DownloadTermsAndConditions?id=${id}`, config)
          .then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',"template"); //or any other extension
            document.body.appendChild(link);
            link.click();        
          });
      };

    const editTermsAndConditions = (values) =>{
        if(values.isNew){
            if(values.type == 'File' && termsAndConditionsFileId){
                const data = { 
                    TermsFileId : termsAndConditionsFileId,
                    termsAndConditionStatus:1,
                    versionName : values.versionName,
                    isTemplate:true,
                    title:values.title
                }
                setLoading(true)
                props.updateTermsAndConditions({
                    termsAndConditionsId: TermsAndConditionsEditId,
                    termsAndConditions:data,
                })
            }else if(values.type == 'Text'){
                const data = { 
                    text : values.text,
                    termsAndConditionStatus:0,
                    versionName:values.versionName,
                    isTemplate:true,
                    title:values.title
                }
                setLoading(true)
                props.updateTermsAndConditions({
                    termsAndConditionsId: TermsAndConditionsEditId,
                    termsAndConditions:data,
                })
            }

        }else if(values.activeVersion && values.version && values.version.value != -1){
            setLoading(true)
            props.updateTermsAndConditions({
                versionId:values.version.id,
                termsAndConditionsId: TermsAndConditionsEditId,
                // title:values.title
            })
        }
        
    }

    return(
        !columns?
        <SimpleLoading/>
        :
        <Fragment>
            <div className="mainHeader organizer font-size-24 mediumWeight">
                <div className="titleItems ">
                    {t("Terms and Conditions templates")}    
                </div>
                <div className="filterItems clickShape">
                    <div onClick={()=>setShowTermsAndConditionsForm(true)} className="createNewPlan">
                        <span className="icon icon-add"></span> Add New Template
                    </div>
                </div>
            </div>
            {errorMessage?
                <div>{errorMessage}</div>
            :null}
            {!_.isEmpty(getAllTermsAndConditions) && columns?
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="adminBoilerplate organizer">
                        <TableComponent 
                            removeToolbar = {false}
                            pageSizes={[5,10,15]} 
                            totalCount={getAllTermsAndConditions?getAllTermsAndConditions.total:0} 
                            selectedList={[]} 
                            rows={rows} 
                            columns={columns}
                            setSelectedList={(e)=>{}} 
                            setDtParameter={setDtParameter} 
                            loading = {loading}
                            dtParameter = {dtParameter}
                            api = 'api/termsAndConditions/GetTermsAndConditionsOfUser'
                        />
                    </div>
                </div>
            </div>
            :null}
            {showTermsAndConditionsForm?
            <NewTermsAndConditionForm
                closeModal={()=>setShowTermsAndConditionsForm(false)} 
                onSubmit={submitTermsAndConditions}
                loading = {loading}
                setFileId = {setTermsAndConditionsFileId}
                setTermsAndConditionsText={setTermsAndConditionsText}
            />
            :null}
            {TermsAndConditionsEditId && showEditTermsAndConditionsFrom?
                <EditTermsAndConditionsModal 
                    id={TermsAndConditionsEditId}
                    closeModal={()=>setShowEditTermsAndConditionsFrom(false)} 
                    onSubmit={editTermsAndConditions}
                    loading = {loading}
                    setFileId = {setTermsAndConditionsFileId}
                    setTermsAndConditionsText={setTermsAndConditionsText}
                />
            :null}
        <br/>
        <br/>
        <br/>
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        getAllTermsAndConditions: state.TermsAndConditionsGetAllTermsAndConditions,
        addNewTermsResult:state.TermsAndConditionsAddTermsGetResults,
        getResults: state.TermsAndConditionsGetResults
    };
  };
  export default (connect(mapStateToProps, {
    getTermsAndConditionApprovalList,
    AddTermsAndConditions,getTermsAndConditionFile,
    updateTermsAndConditions
  })(withRouter(withTranslation()(TermsAndConditionsList))));