import React, {useState, useEffect, Fragment} from 'react' ;
import {connect} from 'react-redux' ;
import {getAllResources, getResourcesByLanguage , getLanguages ,addNewResource , editResource , deleteResource , importResourcesFromExcel , ExportResourcesToExcel ,getPagedResourcesByLanguage} from "./actions";
import _, { filter } from 'lodash' ;
import classNames from 'classnames' ;
import {dropDownStyles} from "../components/formFields";
import SimpleLoading from "../components/simpleLoading";
import SelectBox from '../components/selectBox' ;
import FormModal from '../autocomplete/FormModal';
import DeleteModal from '../components/cmp.modal' ;
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';
import FileUploader from '../components/ButtonFileUploader';
import { saveAs } from 'file-saver';
import ROUTES from '../api-routes';
import { withRouter } from 'react-router-dom';
import Pagination from '../components/pagination';
import apiRoutes from '../api-routes';
import UseToken from '../api-authorization/UseToken';

const Resource = props => {
    
    const {t} = useTranslation();
    const [isLoading , setIsLoading] = useState(false);
    const {resources  , languages , resourceResult } = props ;
    const [AllResources , setAllResources] = useState(null)
    const [selectedResourceId , setSelectedResourceId] = useState(0) ;
    const [selectedLang, setSelectedLang] = useState({}) ;
    const [resultMessage , setResultMessage] = useState(null) ;
    const [languageOptions , setLanguageOptions] = useState([]) ;
    const [showModal , setShowModal] = useState(false) ;
    const [isEdit , setIsEdit] = useState(false) ;
    const [showDeleteModal , setShowDeleteModal] = useState(false) ;
    const [selectedResource , setSelectedResource] = useState(null) ;
    const [message , setMessage] = useState("")
    const [pageNumber , setPageNumber] = useState(1) ;
    const [viewByPage , setViewByPage] = useState(12) ;
    const [resourceCount , setResourceCount] = useState(0)
    const [display , setDisplay] = useState(false)
    const token = UseToken();
    
    useEffect(() => {
        setIsLoading(true)
        props.getLanguages() ;
    } , []) ;

    useEffect(()=>{
        getAllData()
    },[viewByPage , pageNumber])

    useEffect(() => {
        if(!languages){
            return;
        }

        let langs =_.map(languages , lang => {
            return {label : lang.cultureName , value : lang.id , isRtl : lang.isRtl}
        });

        setLanguageOptions(langs) ;
        setSelectedLang(langs[0]) ;

    } , [languages])

    const getAllData = () =>{
        setIsLoading(true)
        const data = {
            PageNumber : pageNumber ,
            PageSize : viewByPage ,
            Lang : selectedLang.value
        };
        props.getPagedResourcesByLanguage(data)
    }

    useEffect(()=>{
        if(!selectedLang){
            return
        }
        i18n.changeLanguage(selectedLang.value)
        if(!_.isEmpty(AllResources && AllResources[selectedLang.value]))
            return 
        getAllData()
    } , [selectedLang])

    useEffect(()=>{
        if(!resources || !selectedLang)
            return 
        setIsLoading(false)
        if(resources.type=='PAGINATION'){
            setResourceCount(resources.totalCount)
            setAllResources(resources.result)
        }
    },[resources])

    useEffect(() => {
        if (!_.isEmpty(resourceResult)) {
            if (resourceResult.action === 'EDIT_RESOURCE') {
                setShowModal(false)
                setResultMessage({success : resourceResult.succeeded , message : resourceResult.succeeded ? 'Edited !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
                /*const data = {
                    PageNumber : pageNumber ,
                    PageSize : viewByPage ,
                    Email : filters.email ,
                    AccessLevel : filters.accessLevel ,
                    StartDate : filters.startDate ,
                    EndDate : filters.endDate
                };
                console.log("edit user getUsers") ;*/
            }
            else if (resourceResult.action === 'NEW_RESOURCE') {
                setShowModal(false)
                setResultMessage({success : resourceResult.succeeded , message : resourceResult.succeeded ? 'Added !' : resourceResult.message}) ;
                if (resourceResult.success) {
                    //setPageNumber(1) ;
                    //setIsEdit(true) ;
                }
            }
            else if (resourceResult.action === 'DELETE_RESOURCE') {
                setResultMessage({success : resourceResult.succeeded , message : resourceResult.succeeded ? 'Deleted !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
            }else if(resourceResult.action == 'IMPORT_RESOURCE'){
                setResultMessage({success : resourceResult.succeeded , message : resourceResult.succeeded ? 'Imported !' : 'Something Unexpectedly Happened ! Please Try Again Later .'}) ;
            }else if(resourceResult.action == 'EXPORT_RESOURCE'){
                saveAs(resourceResult.data)
            }
            setIsLoading(true)
            getAllData()
        }
    } , [resourceResult]) ;
    
    const onSubmit = (values) => {
        if(!selectedLang){
            setResultMessage({success : false , message : 'select languages'}) ;
                
            return 
        }
        values = {...values , languageId : selectedLang.value}
        console.log('values' , values)
        if (isEdit) {
            props.editResource(values) ;
        }
        else {
            props.addNewResource(values) ;
        }
    };

    const onFileUploaded= (fileId) =>{
        props.importResourcesFromExcel(fileId);
    }

    const onExportClick = async () => {
        let baseRoute = ROUTES.api;
        
        const request = fetch(baseRoute + 'api/resources/ExportResourcesToExcelFile', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : `Bearer ${token}`
            }
          })
          request.then(res => res.blob())
          .then(blob => saveAs(blob, 'export.xlsx'))
    }

    // function camelize(str) {
    //     return str.trim()
    //     .split(' ')
    //     .map(a => a.trim())
    //     .map(a => a[0].toUpperCase() + a.substring(1))
    //     .join("")
    //  }

    const onEditClick = (resource) => {
        setSelectedResource(resource);  
        setIsEdit(true) ;
        setShowModal(true) ;
        setResultMessage(null) ;
    };

    const onNewClick = () => {
          setShowModal(true) ;
          setIsEdit(false) ;
          setResultMessage(null) ;
    };

    const onDeleteClick=(resource)=>{
        setResultMessage(null) ;
        setSelectedResource(resource) ;
        setShowDeleteModal(true) ;
    }

    const onConfirmDeleteClick = () => {
        setResultMessage(null) ;
        setShowDeleteModal(false);
        props.deleteResource(selectedResource.id) ;
    };

    const updateList = (selectedPageNumber  , selectedViewByPage) => {
        setPageNumber(selectedPageNumber) ;
        setViewByPage(selectedViewByPage) ;
    };


    return (
        <Fragment>
            <div className="mainHeader organizer mediumWeight">
                <div className="titleItems">
                    <span className="mainTitle middleContext font-size-24">{t('Resource')}</span>
                    {languages?
                
                        <SelectBox
                            label="Languages"
                            value={selectedLang}
                            options={languageOptions}
                            styles={dropDownStyles}
                            onChange={lang => setSelectedLang(lang)}
                        />
                    :null}
                </div>
            {resultMessage ? 
                <div className={classNames("topNormalMargin" , 
                    {'redColor' : !resultMessage.success},
                    {'greenColor' : resultMessage.success})}>
                    {resultMessage.message}
                </div>
            : null}
           
            
                        
            <div style={{display:'flex'}}>
                <div className="createNewPlan" style={{cursor:'pointer' , margin:'5px'}} onClick={onNewClick}>
                    <span className="icon icon-add">{t('New Resource')}</span>
                </div>
                <div className="createNewPlan" style={{cursor:'pointer' , margin:'5px'}}>
                    <span onClick={()=> setDisplay(true)} className="icon icon-add">{t('Import Resources')}</span>
                    <FileUploader storageType={0} display={display} setDisplay={setDisplay} setFileId={onFileUploaded}/>
                </div>
                
                <div className="createNewPlan" style={{cursor:'pointer' , margin:'5px'}} onClick={onExportClick}>
                    <span className="icon icon-add">{t('Export Resources')}</span>
                </div>
            </div>
            </div>
            {isLoading?
                <SimpleLoading/>
            :
            <div className="adminBoilerplate organizer">
                <table className="tableStructure organizer">
                    <thead className="tableHeader">
                        <tr>
                            <th>{t('Name')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    {selectedLang && AllResources &&!_.isEmpty(AllResources)?
                    _.map(AllResources , content => (
                    <tbody key={content.id} >
                        <tr>
                            <td style={{width:'88%'}}>
                                <span className="boldWeight font-size-16"></span>
                                {content.value}
                            </td>
                            <td style={{display: "flex" , alignItems: "center", justifyContent: "center" , alignContent: "center"}}>
                                <span className="editRow leftNormalMargin redBackground clickShape" onClick={()=>onDeleteClick(content)}>
                                    <span className="icon icon-delete whiteColor"></span>
                                </span>
                                <span className="editRow leftNormalMargin greenBackground clickShape">
                                    <span className="icon icon-edit whiteColor"onClick={()=>onEditClick(content)}></span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    )):null}

                </table>
                <Pagination pageNumber={pageNumber} viewByPage={viewByPage} changeData={updateList} totalPages={Math.ceil(resourceCount/viewByPage)}/>
            </div>
            }
            
            
            {showModal ?
                <FormModal 
                    title= {isEdit?"Edit resource":"New resource"}
                    initialValues={isEdit ? 
                        selectedResource : 
                        {
                            value:''
                        }
                    } 
                    resultMessage={resultMessage} 
                    onSubmit={(values)=>onSubmit(values)} edit={isEdit} 
                    closeModal={() => setShowModal(false)}
                /> 
            : null}
            {showDeleteModal && selectedResourceId !== null ? 
                <DeleteModal closeModal={() => setShowDeleteModal(false)} 
                    acceptAction={onConfirmDeleteClick} data={null} 
                    title="Delete resource" 
                    modalMessage={'Are you sure you want to delete this resource?'} 
                    resultMessage={resultMessage}
                /> 
            : null}

        </Fragment>
    )
}


const mapStateToProps = (state , ownProps) => {
    return {
        //resources : state.resources ,
        languages : state.languages ,
        resourceSelectedList: state.selectedResources,
        resourceResult : state.resourceResult,
        resources : state.resourcesByLang,
    } ;
};

export default connect(mapStateToProps , {getAllResources, getResourcesByLanguage ,getPagedResourcesByLanguage , getLanguages ,addNewResource , editResource , deleteResource , importResourcesFromExcel , ExportResourcesToExcel})(withRouter(Resource)) ;

